import {action, makeObservable, observable, computed} from "mobx";
import axios from "axios";
import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module';
import {preloadImage, getQueryParams} from "../helpers";
import nl from '../lang/nl.json';
import en from '../lang/en.json';
import es from '../lang/es.json';
import fr from '../lang/fr.json';
import de from '../lang/de.json';
import moment from "moment";
import * as Sentry from "@sentry/react";
import TiktokPixel from "tiktok-pixel";

let timer;

const locales = {nl, en, de, fr, es};

export default class DeviceStore {

  meta = {};

  injectedTheme = false;

  navigate = null;

  forceFooter = false;

  setForceFooter(){
    this.forceFooter = true;
  }

  constructor(){
    makeObservable(this, {
      iframe: observable,
      theme: observable,
      setTheme: action,
      referrer: observable,
      organisationData: observable,
      getEvents: action,
      organisation_info: observable,
      show_organisation_info: observable,
      forceFooter: observable,
      setForceFooter: action,
      hasTrackers: observable,
      eventData: observable,
      loading: observable,
      selectedTickets: observable,
      getEvent: action,
      date: observable,
      updateTicketAmount: action,
      updateVariantAmount: action,
      totalPrice: computed,
      clientId: observable,
      setClientId: action,
      totalTickets: computed,
      getTicketTypes: computed,
      entranceShop: computed,
      reserve: action,
      step: observable,
      setStep: action,
      reservation: observable,
      error: observable,
      donation: observable,
      wantsWhatsApp: observable,
      setWhatsApp: action,
      setError: action,
      queueData: observable,
      setQueueData: action,
      time: observable,
      setTime: action,
      formData: observable,
      customFormData: observable,
      setFormValue: action,
      setCustomFormValue: action,
      locale: observable,
      defaultLocale: observable,
      setLocale: action,
      setDefaultLocale: action,
      validateEmail: action,
      invite_id: observable,
      toggleSeat: action,
      removeSeat: action,
      setInviteId: action,
      redirect: observable,
      wantsNewsletter: observable,
      setNewsletter: action,
      toggleOrganisationInfo: action,
      setDate: action,
      selectedSeats: observable,
      selectedVariants: observable,
      setReferrer: action,
      setDonation: action,
      showFooter: computed,
    });

    try {
      let storage = localStorage;
    } catch (e) {
      alert('Je hebt third-party cookies geblokkeerd, de webshop functioneert niet zonder deze cookies.')
    }

    if(window.theme){
      this.setTheme(window.theme);
    }

    let referrerReceived = false;

    try {
      let query = getQueryParams();

      query.forEach(q => {
        this.meta[q.key] = q.value;
      })

      const referrerQuery = query.find(q => q.key === 'referrer');
      const utmQuery = query.find(q => q.key === 'utm_campaign');

      if(utmQuery && utmQuery.value !== 'undefined'){
        referrerReceived = true;
        this.setReferrer(utmQuery.value);
      } else if (referrerQuery) {
        referrerReceived = true;
        this.setReferrer(referrerQuery.value);
      }
    } catch (e) {
      //
    }

    if(!referrerReceived) {
      window.addEventListener('message', ({data}) => {
        if (data.type === 'setReferrer' && data.value) {
          this.setReferrer(data.value);
        }
      }, false);
    }

    window.addEventListener('message', ({data}) => {
      if(data.type === 'setMeta'){
        this.meta = data.value;
      }
      if(data.type === 'theme'){
        this.injectedTheme = true;
        this.setTheme(data.value, false)
      }

      if(data.type === 'updateTicketAmount' && this.eventData){
        const {organisation_slug, shop_id} = this.eventData;
        this.navigate('/'+organisation_slug + '/' + shop_id)
        this.updateTicketAmount(data.value.tickettype_id, data.value.amount);
      }

    })

    window.addEventListener('beforeunload', this.expireReservation)

    this.setTime();

    this.setClientId();

    let locale = (navigator.language || navigator.userLanguage).substr(0, 2);

    if(localStorage.locale){
      locale = localStorage.locale;
    }

    const locales = [
        'nl',
        'de',
        'en',
        'fr',
        'es',
    ];

    if(locales.includes(locale)){
      this.locale = locale;
      document.documentElement.setAttribute('lang', locale);
    }

    try {
      this.iframe = window.self !== window.top;
    } catch (e) {
      this.iframe = true
    }

  }

  setClientId(slug = '')
  {
    this.clientId = localStorage[slug + '_client_id'];
    if(!this.clientId){
      this.clientId = this.uuidv4();
      localStorage[slug + '_client_id'] = this.clientId;
    }
  }

  step = null;

  setStep = (value) => {
    this.step = value;
  }

  queueData = {};

  date = null;

  hasTrackers = false;
  trackersLoaded = false;
  tagmanagerLoaded = false;

  organisation_info = null;
  show_organisation_info = false;

  referrer = document.referrer ? document.referrer : null;

  iframe = false;

  theme = {};

  redirect = null;

  apiUrl = process.env.REACT_APP_API_URL ?? 'https://api.mijnetickets.nl';
  queueUrl = process.env.REACT_APP_QUEUE_URL ?? 'https://queue.mijnetickets.nl';

  loading = false;

  selectedSeats = [];

  locale = 'nl';

  defaultLocale = 'nl';

  invite_id = null;

  error = null;

  time = null;

  clientId = null;

  organisationData = null;

  eventData = null;

  selectedTickets = [];
  selectedTicketsReserved = [];

  selectedVariants = {};

  formData = {
    telephone: ''
  };

  donation = '';

  customFormData = {};

  reservation = null;

  wantsNewsletter = false;

  wantsWhatsApp = false;

  setDonation(value){
    if(value < 0){
      value = 0;
    }
    this.donation = value;
  }

  setTheme(theme, iframe = null){

    let queryTheme = getQueryParams('theme');
    if(queryTheme){
      try {
        queryTheme = JSON.parse(atob(queryTheme));
        iframe = false;
        if(queryTheme) {
          theme = queryTheme;
        }
      } catch (e) {
        //
      }
    }

    this.theme = theme;

    if(iframe === null){
      iframe = this.iframe;
    }

    if(theme.background_color && (!iframe || queryTheme)){
      document.body.style.backgroundColor = theme.background_color;
    } else {
      document.body.style.backgroundColor = null;
    }
    if(theme.background_image){
      document.body.style.backgroundImage = 'url("'+theme.background_image+'")'
    } else {
      document.body.style.backgroundImage = null;
    }
    document.body.classList.remove('dark')
    if(theme.dark){
      document.body.classList.add('dark')
    }
    if(theme.custom_css){
      this.addcss(theme.custom_css)
    }
  }

  setReferrer = (value) => {
    this.referrer = value;
  }

  toggleOrganisationInfo = () => {
    this.show_organisation_info = !this.show_organisation_info;
  }

  setLocale = (locale) => {
    if(locale) {
      localStorage.locale = locale;
      this.locale = locale;
      document.documentElement.setAttribute('lang', locale);
    }
  }

  setDefaultLocale = (locale) => {
    if(locale) {
      this.defaultLocale = locale;
    }
}

  // Translate
  t = (key, variables = []) => {
    try {
      let string = locales[this.locale][key] ?? (locales.en[key] ?? key)
      variables.forEach((v, i) => {
        string = string.replace('{'+(i + 1)+'}', v);
      })
      return string;
    } catch (e) {
      return key;
    }
  }


  setTime = () => {
    this.time = Math.floor(Date.now() / 1000);
  }

  setError = (error = null) => {
    this.error = error
  }

  setFormValue = (key, value) => {
    this.formData[key] = value;
  }

  setCustomFormValue = (id, value) => {
    this.customFormData[id] = value;
  }

  cacheFormData = () => {
    localStorage.setItem('customer', JSON.stringify({...this.formData, created_at: new Date().getTime()}) );
  }

  getEvents = async (organisation_slug) => {
    this.error = null;
    this.selectedTickets = [];
    this.selectedSeats = [];
    this.eventData = null;
    const {data} = await axios.get(this.apiUrl + `/shop/${organisation_slug}`);

    this.organisationData = data;
    this.organisation_info = data.organisation;
    this.setDefaultLocale(data.organisation.locale);
    document.title = data.organisation.name + ' - mijnetickets.nl';

    const {theme} = data;
    if(theme && !this.injectedTheme){
      this.setTheme(theme)
    }

  }

  getInviteByCode = async (organisation_slug, shop_id, code) => {
    try {
      const {data} = await axios.post(this.apiUrl + `/shop/${organisation_slug}/${shop_id}/invite`, {
        code
      });
      this.invite_id = data.id;
      this.eventRecentLoaded = false;
      await this.getEvent(organisation_slug, shop_id);
      return true;
    } catch (e){
      return false;
    }
  }

  expireReservation = () => {
    if(this.reservation) {
      axios.post(this.apiUrl + `/shop/session/expire`, new URLSearchParams({
        client_id: this.clientId,
        reservation_id: this.reservation.id,
      }));
      this.reservation = null;
    }
  }

  setDate = (id) => {
    this.date = id;
    if(!id && this.reservation){
      this.expireReservation();
      this.reservation = null;
      this.selectedTickets = [];
    }
  }

  get getTicketTypes() {
    return this.date ? this.eventData.dates.find(d => d.id === this.date).tickets : this.eventData.tickets;
  }

  get entranceShop(){
    return this.eventData?.entrance_shop;
  }

  getEvent = async (organisation_slug, shop_id, updating = false) => {

    if(this.eventRecentLoaded){
      return true;
    }

    // Prevent quickly double loading
    this.eventRecentLoaded = true;
    setTimeout(() => {
      this.eventRecentLoaded = false;
    }, 1000)


    this.error = null;
    if(!updating) {
      this.selectedTickets = [];
      this.reservation = null;
      this.date = null;

    }
    try {
      let {data} = await axios.get(this.apiUrl + `/shop/${organisation_slug}/${shop_id}?client_id=${this.clientId}&invite=${this.invite_id ? this.invite_id : ''}&u=`+(updating ? '1' : '0'));

      if(data.theme && data.theme.image){
        await preloadImage(data.theme.image)
      }

      const timestamp = moment().unix();

      // Fix timing issues
      if(data.event.sell_start){
        data.event.sell_start = timestamp + data.event.sell_start_in;
      }
      if(data.event.sell_end){
        data.event.sell_end = timestamp + data.event.sell_end_in;
      }

      data.tickets = data.tickets.map(ticket => {
        if(ticket.sell_start){
          ticket.sell_start = timestamp + ticket.sell_start_in;
        }
        if(ticket.sell_end){
          ticket.sell_end = timestamp + ticket.sell_end_in;
        }
        return ticket;
      })

      data.organisation_slug = organisation_slug;
      data.shop_id = shop_id;

      this.eventData = data;

      if(data.valid_invite === false){
        this.setError(this.t('invalid_invite'))
      }

      this.postMessage('eventLoaded');

      this.organisation_info = data.organisation;
      this.setDefaultLocale(data.organisation.locale);
      document.title = data.event.name;
      const {theme} = this.eventData;

      if(theme){
        if(!this.injectedTheme) {
          this.setTheme(theme)
        }
      }

      try {
        const customFormData = getQueryParams('customFormData');
        if (customFormData) {
          Object.entries(JSON.parse(atob(customFormData))).forEach(entry => {
            const field = data.custom_form.fields.find(field => field.name === entry[0])
            if (field) {
              this.customFormData[field.id] = entry[1]
            }
          })
        }
      } catch (e) {
        console.log(e)
      }

      if(data.customer){
        this.formData.first_name = data.customer.first_name;
        this.formData.last_name = data.customer.last_name;
        this.formData.email = data.customer.email;
        this.formData.email_confirmation = data.customer.email;
        this.formData.company_name = data.customer.company_name;
      } else {
        try {
          if (localStorage.getItem('customer')) {
            const customer = JSON.parse(localStorage.getItem('customer'));
            // if(customer.created_at > new Date().getTime() - (7 * 24 * 3600 * 1000)) {
              if(!this.formData.first_name) {
                this.formData.first_name = customer.first_name || '';
              }
              if(!this.formData.last_name) {
                this.formData.last_name = customer.last_name || '';
              }
              if(!this.formData.email) {
                this.formData.email = customer.email || '';
              }
              if(!this.formData.email_confirmation) {
                this.formData.email_confirmation = customer.email || '';
              }
              if (data.form_fields.birthday && !this.formData.birthday) {
                this.formData.birthday = customer.birthday || '';
              }
              if (data.form_fields.gender && !this.formData.gender) {
                this.formData.gender = customer.gender || '';
              }
              if (data.form_fields.telephone && !this.formData.telephone) {
                this.formData.telephone = customer.telephone || '';
              }
              if (data.form_fields.city && !this.formData.city) {
                this.formData.city = customer.city || '';
              }
              if (data.form_fields.address && !this.formData.address) {
                this.formData.address = customer.address || '';
              }
              if (data.form_fields.zipcode && !this.formData.zipcode) {
                this.formData.zipcode = customer.zipcode || '';
              }
            if (data.form_fields.country && !this.formData.country) {
              this.formData.country = customer.country || '';
            }
            // }
          }
        } catch (e) {
          //
        }
      }


      if(this.eventData.fb_pixel || this.eventData.tiktok_pixel){
        this.hasTrackers = true;
      }

      if (this.eventData.tagmanager) {
        this.loadTagManager();
      }

      if(localStorage.CookieConsent || document.cookie.includes('CookieConsent=true')){
        this.loadTrackers();
      }

    } catch (e){
      if(e.response?.status === 404){
        window.location.href = `/${organisation_slug}`;
        return true;
      } else {
        this.error = 'Er ging iets fout bij het ophalen van de informatie. Probeer het later nog eens.'
      }
      return false;
    }

    return true;
  }

  checkCouponCode = async (code) => {

    const {organisation_slug, shop_id} = this.eventData;

    try {
      const {data} = await axios.post(this.apiUrl + `/shop/${organisation_slug}/${shop_id}/discount?client_id=${this.clientId}`, {
        code,
        reservation_id: this.reservation.id,
      });
      return data;
    } catch (e){
      console.log(e)
      return false;
    }
  }

  // Add a new ticket amount
  updateTicketAmount = (id, amount) => {

    if(this.reservation){
      this.expireReservation();
    }

    const type = this.getTicketTypes.find(t => t.id === id);

    if(!type){
      return;
    }

    if(amount > type.userlimit){
      amount = type.userlimit;
    }

    this.selectedTickets = this.selectedTickets.filter(t => t.tickettype_id !== id);

    for (let i = 0; i < amount; i++) {
      this.selectedTickets.push({
        tickettype_id: id
      })
    }
    clearTimeout(timer)
    // timer = setTimeout(this.reserve, 300);

    let selected = {};
    this.selectedTickets.forEach(t => {
      if(!selected[t.tickettype_id]){
        selected[t.tickettype_id] = {
          tickettype_id: t.tickettype_id,
          amount: 0
        }
      }
      selected[t.tickettype_id].amount++;
    })

    this.postMessage('updatedSelectedTickets', Object.values(selected));
  }

  updateVariantAmount = (variant, amount) => {
    this.selectedTickets = this.selectedTickets.filter(t => t.variant_id !== variant.id || !t.variant_id);
    for (let i = 0; i < amount; i++) {
      this.selectedTickets.push({
        tickettype_id: variant.tickettype_id,
        variant_id: variant.id,
      })
    }
    clearTimeout(timer)
    // timer = setTimeout(this.reserve, 300);
  }

  removeSeat = (ticket, seat) => {
    this.selectedTickets = this.selectedTickets.filter(t => t.seat_id !== seat.id)
  }

  toggleSeat = async (ticket, seat, error = false) => {
    if(this.selectedTickets.filter(t => t.seat_id === seat.id).length){
      this.selectedTickets = this.selectedTickets.filter(t => t.seat_id !== seat.id)
    } else {
      this.selectedTickets.push({
        tickettype_id: ticket.id,
        seat_id: seat.id
      })
    }
    if(!error) {
      clearTimeout(timer)
      return await this.reserve();
    }
  }

  // Get the total order price
  get totalPrice() {
    const {event} = this.eventData;

    let total = 0;

    this.getTicketTypes.forEach(ticket => {
      const ticketCount = this.selectedTickets.filter(t => t.tickettype_id === ticket.id).length;
      total += ticketCount * ticket.price;
      if(ticket.price){
        total += ticketCount * event.customer_ticket_fee;
      }
    })

    if(this.donation){
      total += parseFloat(this.donation);
    }

    return total;
  }

  get showFooter(){
    return this.eventData || this.organisationData || this.queueData.status === 'queue' || this.queueData.status === 'pre-queue' || this.forceFooter;
  }

  // Get the total selected tickets
  get totalTickets() {
    return this.selectedTickets.length;
  }

  // Create a new reservation
  reserve = async () => {

    this.loading = true;
    const {organisation_slug, shop_id} = this.eventData;

    const expires_in = (this.queueData.expires_at || 0) - moment().unix();

    if(this.queueData.id !== shop_id || expires_in < 60) {
      await this.fetchQueue(shop_id, 'session');
      if (this.queueData.status === 'queue' || this.queueData.status === 'error' || this.queueData.status === 'pre-queue') {
        this.loading = false;
        this.selectedTickets = this.selectedTicketsReserved;
        this.navigate('/'+organisation_slug + '/' + shop_id + '/queue')
        return false;
      }
    }

    const selectedTickets = [...this.selectedTickets];

    // if(event.ticket_order_limit && event.ticket_order_limit < selectedTickets.length){
    //   this.selectedTickets = this.selectedTicketsReserved;
    //   this.error = this.t('max_tickets_error', [event.ticket_order_limit])
    //   this.loading = false;
    //   return;
    // }

    this.error = null;

    let postData = {
      locale: this.locale,
      client_id: this.clientId,
      tickets: selectedTickets,
      queue_token: this.queueData.token,
    };

    if(this.date){
      postData.date_id = this.date;
    }
    if(this.invite_id){
      postData.invite_id = this.invite_id;
    }
    if(this.donation){
      postData.donation = this.donation;
    }

    try {
      let {data} = await axios.post(this.apiUrl + `/shop/${organisation_slug}/${shop_id}/reservation`, postData);
      if(!this.reservation && window.dataLayer){
        window.dataLayer.push({
          event: "ReservationCreated",
        });
      }

      data.expires = moment().unix() + data.expires_in;

      this.reservation = data;
    } catch (e){
      this.error = e.response?.data?.message || this.t('something_went_wrong');
      //this.selectedTickets = this.selectedTicketsReserved;
      return false;
    } finally {
      if(this.selectedTickets.length === 0 && !this.donation){
        this.reservation = null;
      }
      this.loading = false;
    }

    this.selectedTicketsReserved = selectedTickets;
    return true;
  }

  getBlockedSeats = async() => {
    const {organisation_slug, shop_id} = this.eventData;
    try {
      const {data} = await axios.get(this.apiUrl + `/shop/${organisation_slug}/${shop_id}/seats/blocked`, {
        params: {
          client_id: this.clientId,
          date_id: this.date,
        }
      });
      return data;
    } catch (e){
      return [];
    }
  }

  blockSeats = async (seats) => {
    const {organisation_slug, shop_id} = this.eventData;
    try {
      await axios.post(this.apiUrl + `/shop/${organisation_slug}/${shop_id}/seats/blocked?client_id=${this.clientId}`, {
        seats: seats.map(s => s.id),
        date_id: this.date,
      });
    } catch (e){
      console.log(e)
    }
  }

  checkReservation = async () => {
    if(!this.reservation || !this.eventData || this.redirect || this.loading){
      return;
    }

    const {organisation_slug, shop_id} = this.eventData;

    try {
      await axios.get(this.apiUrl + `/shop/${organisation_slug}/${shop_id}/reservation/${this.reservation.id}?client_id=${this.clientId}`);
    } catch (e){
      window.dataLayer.push({
        event: "Reservation expired",
      });
      this.selectedTickets = [];
      this.reservation = null;
      this.navigate('/'+organisation_slug + '/' + shop_id)
      setTimeout(() => {
        this.setError(this.t('reservation_expired'))
      }, 100)
    }
  }

  setInviteId = (invite_id) => {
    this.invite_id = invite_id;
  }

  // Validate the email address
  validateEmail = async () => {

    this.error = null;

    const {email, email_confirmation} = this.formData;

    if(!email){
      this.error = this.t('email_confirmation_error');
      return false;
    }

    if(email !== email_confirmation){
      this.error = 'Het ingevulde e-mailadres komt niet overeen.';
      return false;
    }

    if(email.endsWith('@outlook.com') || email.endsWith('@hotmail.com') || email.endsWith('@gmail.com') || email.endsWith('@icloud.com')){
      return true;
    }

    const {organisation_slug, shop_id} = this.eventData;
    try {
      await axios.post(this.apiUrl + `/shop/${organisation_slug}/${shop_id}/validate/email`, {
        email
      })
      return true;
    } catch (e){
      this.error = this.t('email_invalid');
      return false;
    }
  }

  setWhatsApp = ({target}) => {
    this.wantsWhatsApp = target.checked;
  }

  setNewsletter = ({target}) => {
    this.wantsNewsletter = target.checked;
  }

  createOrder = async (method, coupon) => {
    const {organisation_slug, shop_id} = this.eventData;
    const reservation_id = this.reservation?.id;
    this.reservation = null;
    try {
      const form = this.formData;
      form.custom_form = this.customFormData;
      const {data} = await axios.post(this.apiUrl + `/shop/${organisation_slug}/${shop_id}/pay?client_id=${this.clientId}`, {
        invite_id: this.invite_id,
        pay_method: method,
        meta: this.meta,
        lang: this.locale,
        donation: this.donation,
        sms: this.wantsWhatsApp,
        discount_code: coupon,
        reservation_id: reservation_id,
        form_data: form,
        newsletter: this.wantsNewsletter,
        referrer: this.referrer,
      })

      window.dataLayer.push({
        event: "Order created",
        orderId: data.order_id,
      });

     this.pixelCheckout();

      this.redirect = data.redirect;
      try {
        window.top.location.href = this.redirect;
      } catch (e){

      }
      return true;
    } catch (e){
      setTimeout(() => {
        if(e.response?.data?.message){
          this.error = e.response?.data?.message;
        } else {
          this.error = 'Er ging iets fout, probeer het nog een keer';
        }
      }, 500)
      return false;
    }
  }

  uuidv4() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }

  addcss = (css) => {
    if(document.getElementById('customCss')){
      document.getElementById('customCss').remove()
    }

    if (!css) return

    var head = document.getElementsByTagName('head')[0]
    var s = document.createElement('style')
    s.setAttribute('type', 'text/css')
    s.setAttribute('id', 'customCss');
    if (s.styleSheet) {
      s.styleSheet.cssText = css
    } else {
      s.appendChild(document.createTextNode(css))
    }

    head.appendChild(s)
  }

  loadTrackers = () => {

    if(this.trackersLoaded) return;

    this.trackersLoaded = true;

    if(this.eventData) {
      if (this.eventData.fb_pixel) {
        this.loadFbPixel();
      }
      if(this.eventData.tiktok_pixel){
        this.loadTiktokPixel();
      }

    }
  }

  loadTagManager = () => {
    if(this.tagmanagerLoaded){
      return;
    }
    this.tagmanagerLoaded = true;
    if(this.eventData.tagmanager.toLowerCase().startsWith('gtm-')) {
      TagManager.initialize({
        gtmId: this.eventData.tagmanager,
      });
    } else {
      window.gtag('config', this.eventData.tagmanager);
    }

  }

  loadFbPixel = () => {
    ReactPixel.init(this.eventData.fb_pixel, {}, {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: false, // enable logs
    });
    ReactPixel.pageView();
  }

  loadTiktokPixel = () => {
    TiktokPixel.init(this.eventData.tiktok_pixel, {}, {debug: false});
    TiktokPixel.pageView();
  }

  pixelAddToCart = (name, id, price) => {
    if (this.eventData.fb_pixel) {
      ReactPixel.track('AddToCart', {
        value: price,
        currency: 'EUR',
        content_ids: id,
        content_name: name
      });
    }
    if(this.eventData.tiktok_pixel){
      TiktokPixel.track('AddToCart', {
        value: price,
        currency: 'EUR',
        content_id: id,
        description: name
      });
    }
  }

  pixelCheckout = () => {
    if (this.eventData.fb_pixel) {
      ReactPixel.track('InitiateCheckout');
    }
    if (this.eventData.tiktok_pixel) {
      TiktokPixel.track('InitiateCheckout');
    }
  }

  setQueueData = (data) => {
    if(data.preQueueTime){
      data.preQueueTime = moment().unix() + data.preQueueTimeIn
    }
    this.queueData = data;
    if(data.theme && !this.injectedTheme){
      this.setTheme(data.theme);
    }
  }

  fetchQueue = async (queue_id, defaultStatus = 'queue') => {

    const hash = btoa(JSON.stringify({
      queue_id: decodeURIComponent(queue_id).replace(/[^a-zA-Z0-9-]/g, ''),
      origin: window.location.origin + window.location.pathname,
    })).replace(/\//g, "-").replace(/\+/g, "_");
    try {
      const {data} = await axios.get(this.queueUrl+`/api/v2/fetch/${hash}/${this.clientId}`);
      this.setQueueData(data);
    } catch (e) {
      // Sentry.captureException(e);
      this.setQueueData({
        status: defaultStatus,
      })

      if(defaultStatus === 'error'){
        this.error = 'Er ging iets fout bij het ophalen van de informatie. Probeer het later nog eens.';
      }
    }
  }

  postMessage = (type, value) => {
    window.parent.postMessage({
      type,
      value: typeof value === 'object' ? JSON.parse(JSON.stringify(value)) : value
    }, "*");
  }
}
